import React, { useState } from "react";

export const MessagesContext = React.createContext(null);

const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([
    {
      type: 1,
      image: "favicon.ico",
      text: `Hello! Welcome to the Social Reviews Admin Page!`,
    },
    {
      type: 1,
      image: "favicon.ico",
      text: "What would you like to do today?",
    },
  ]);

  return (
    <MessagesContext.Provider value={{ messages, setMessages }}>
      {children}
    </MessagesContext.Provider>
  );
};

export default MessageProvider;
