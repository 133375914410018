import styles from "./Header.module.css";

const Header = (props) => {
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.titleBar}>
          <img alt="Belle Tire logo" className={styles.navLogo} src={props.image}></img>
        </div>
      </div>
    </div>
  );
};
export default Header;
