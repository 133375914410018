import { useState, useEffect, useContext } from "react";
import Spinner from "../Spinner/Spinner.js";
import "../EditDialog/EditDialog.css";
import { MessagesContext } from "../MessagesContext.js";
import adminIcon from "../../images/Blue_Admin.svg"

const EditDialog = (props) => {
  const [google, setGoogle] = useState();
  const [facebook, setFacebook] = useState();
  const [yelp, setYelp] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);

  const { messages, setMessages } = useContext(MessagesContext);

  const handleUrlsUpdate = async () => {
    //show spinner
    setIsLoading(true);
    try {
      const response = await fetch(`/stores/${props.unitNum}/urls`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          facebookUrl: facebook,
          googleUrl: google,
          yelpUrl: yelp,
        }),
      });
      if (response.status >= 400) {
        setMessages(
            messages.concat([
              {
                type: 1,
                image: "favicon.ico",
                text: `Well, this is embarrassing! I am experiencing issues with updating store #${props.unitNum}! Please make sure all of the urls are less than 200 characters. If this issue persists, then contact K-TEAM.`,
              },
            ])
        );
      } else {
        setMessages(
          messages.concat([
            {
              type: 1,
              image: "favicon.ico",
              text: `I updated store #${props.unitNum}! Anything else?`,
            },
          ])
        );
        props.onComplete(true);
      }
    } catch (err) {
      console.log(err);
    }
    //show spinner
    setIsLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCancel = () => {
    //push new message
    setMessages(
      messages.concat([
        {
          type: 0,
          image: adminIcon,
          text: `Never mind!`,
        },
        {
          type: 1,
          image: "favicon.ico",
          text: `Had a change of heart? What's next?`,
        },
      ])
    );
    //reset
    props.onComplete(false);
  };
  useEffect(() => {
    setCanSubmit(facebook && google && yelp);
  }, [facebook, google, yelp])
  useEffect(() => {
    const onComponentLoad = async () => {
      if (!props.isNew) {
        //show spinner
        setIsLoading(true);
        try {
          const response = await fetch(`/stores/${props.unitNum}/urls`);
          const data = await response.json();
          setGoogle(data.googleUrl);
          setFacebook(data.facebookUrl);
          setYelp(data.yelpUrl);
        } catch (err) {
          console.error(err);
        }
        //hide spinner
        setIsLoading(false);
      }
    };
    onComponentLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isLoading && <Spinner></Spinner>}
      {!isLoading && (
        <div className="edit_input_sections_wrapper cta_section">
          <div className="edit_input_section">
            <label htmlFor="google">Google: </label>
            <input
              className="edit_dialog_input input"
              id="google"
              name="google_feedback"
              type="text"
              value={google}
              onChange={(event) => setGoogle(event.target.value)}
            ></input>
          </div>
          <div className="edit_input_section">
            <label htmlFor="facebook">Facebook: </label>
            <input
              className="edit_dialog_input input"
              id="facebook"
              name="facebook_feedback"
              type="text"
              value={facebook}
              onChange={(event) => setFacebook(event.target.value)}
            ></input>
          </div>
          <div className="edit_input_section">
            <label htmlFor="yelp">Yelp: </label>
            <input
              className="edit_dialog_input input"
              id="yelp"
              name="yelp_feedback"
              type="text"
              value={yelp}
              onChange={(event) => setYelp(event.target.value)}
            ></input>
          </div>
          <div className="button_section">
            <button
              onClick={handleUrlsUpdate}
              className={`${canSubmit ? "" : "submit-invalid"} button button_primary`}
            >
              Submit
            </button>
            <button onClick={handleCancel} className="button button_secondary">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default EditDialog;
