import { Route } from "react-router";
import { Layout } from "./components/Layout";
import Home from "./components/Home";
import "./global.css";
import MessageProvider from "./components/MessagesContext";

const App = () => {
  return (
    <MessageProvider>
      <Layout>
        <Route exact path="/" component={Home} />
      </Layout>
    </MessageProvider>
  );
};
export default App;
