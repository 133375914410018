import ChatBubble from "react-chat-bubble";
import { useState, useContext, useEffect } from "react";
import EditDialog from "./EditDialog/EditDialog";
import { MessagesContext } from "./MessagesContext";
import sendArrow from "../images/send_arrow.svg";
import adminIcon from "../images/Blue_Admin.svg";
import blueArrow from "../images/Blue_Arrow.svg";
import whiteArrow from "../images/White_Arrow.svg";
import "./Home.css";

const Home = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [unitNum, setUnitNum] = useState();
  const [showButtons, setShowButtons] = useState(true);
  const [showUnitNumInput, setShowUnitNumInput] = useState(true);
  const { messages, setMessages } = useContext(MessagesContext);
  const [showSendIcon, setShowSendIcon] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [newStores, setNewStores] = useState([]);
  const [showStores, setShowStores] = useState(false);
  const [selectedStore, setSelectedStore] = useState();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      //show Edit dialog
      handleUnitNumSubmit();
    }
  };
  const showUpdateDialog = () => {
    setIsUpdate(true);
    setShowUnitNumInput(true);
    setShowButtons(false);
    setShowDropdown(false);
    setShowStores(false);
    setMessages(
      messages.concat([
        {
          type: 0,
          image: adminIcon,
          text: `I want to update a store's urls`,
        },
        {
          type: 1,
          image: "favicon.ico",
          text: `Okay! Please enter the unit num.`,
        },
      ])
    );
  };
  const reset = () => {
    setShowButtons(true);
    setIsUpdate(false);
    setIsNew(false);
    setShowUnitNumInput(false);
    setShowEditDialog(false);
  };
  const resetFromAdd = (updateNewStoreList) => {
    reset();
    //reset the select store so the dropdown can reset
    setSelectedStore();
    //remove updated store from "Stores with no urls" array
   if (updateNewStoreList){
    var filteredStoreList = newStores.filter(
      (store) => store.unitNum !== parseInt(unitNum)
    );
    setNewStores(filteredStoreList);
   }
  };
  const showNewStoreOptions = async () => {
    setIsNew(true);
    setShowDropdown(true);
    setShowButtons(false);
    setMessages(
      messages.concat([
        {
          type: 0,
          image: adminIcon,
          text: `I want to add new urls`,
        },
        {
          type: 1,
          image: "favicon.ico",
          text: `For which store? Check out your options below!`,
        },
      ])
    );
  };
  const handleAddNewStore = (unitNum, name) => {
    setSelectedStore(`#${unitNum} - ${name}`);
    setUnitNum(unitNum);
    setShowEditDialog(true);
    setShowDropdown(false);
    setMessages(
      messages.concat([
        {
          type: 0,
          image: adminIcon,
          text: `For store # ${unitNum}`,
        },
      ])
    );
  };
  const handleUnitNumChange = (value) => {
    setUnitNum(value);
    value ? setShowSendIcon(true) : setShowSendIcon(false);
  };
  const handleUnitNumSubmit = () => {
    setShowEditDialog(true);
    setShowUnitNumInput(false);
    setMessages(
      messages.concat([
        {
          type: 0,
          image: adminIcon,
          text: `Let's update store #${unitNum}`,
        },
      ])
    );
    //check to see if unitNum is in the new store list
    const found = newStores.some(
      (store) => store.unitNum === parseInt(unitNum)
    );

    //if so, set isUpdate to false ++ isNew to true ++
    //show a new message
    if (found) {
      setIsUpdate(false);
      setIsNew(true);
      setMessages(
        messages.concat([
          {
            type: 1,
            image: "favicon.ico",
            text: `It looks like store #${unitNum} is new! You can add its information below`,
          },
        ])
      );
    }
  };
  const handleDropdown = () => {
    let toggledValue = !showStores;
    setShowStores(toggledValue);
  };
  useEffect(() => {
    const fetchNewStores = async () => {
      const response = await fetch("stores/no-urls");
      const data = await response.json();
      setNewStores(data);
    };
    fetchNewStores();
  }, []);
  return (
    <div className="home_wrapper">
      <ChatBubble
        messages={messages}
        onNewMessage={() => console.log("I'm here so I won't get fined")}
      />
      {showButtons && (
        <div className="button_section cta_section">
          <input
            type="button"
            value="Update a Store's Urls"
            className="button_primary button"
            onClick={showUpdateDialog}
          ></input>
          <input
            type="button"
            value="Add a Store's Urls"
            className="button_primary button"
            onClick={showNewStoreOptions}
          ></input>
        </div>
      )}
      {isUpdate && showUnitNumInput && (
        <div className="home_unit_num_wrapper">
          <input
            className="home_unit_num_input input cta_section"
            type="number"
            placeholder="Unit Num"
            onKeyPress={handleKeyPress}
            onChange={(event) => handleUnitNumChange(event.target.value)}
            onFocus={() => setShowButtons(false)}
          ></input>
          {showSendIcon && (
            <img
              onClick={handleUnitNumSubmit}
              className="home_send_icon"
              alt="Send Message"
              src={sendArrow}
            />
          )}
          <button onClick={reset} className="cancel_btn">
            Cancel
          </button>
        </div>
      )}
      {isUpdate && showEditDialog && (
        <EditDialog
          isNew={isNew}
          onComplete={reset}
          unitNum={unitNum}
        ></EditDialog>
      )}
      {isNew && showDropdown && (
        <div className="dropdown_wrapper">
          <div
            className={`dropdown_label ${showStores ? `active` : ``}`}
            onClick={handleDropdown}
          >
            <div>{selectedStore ? selectedStore : `Select a store`}</div>
            <img
              alt="dropdown arrow"
              src={`${showStores ? whiteArrow : blueArrow}`}
              className={`custom_arrow ${showStores ? `up` : `down`}`}
            ></img>
          </div>
          <div>
            {showStores && (
              <ul className="options_wrapper">
                {newStores.map((store) => (
                  <li
                    key={store.unitNum}
                    className="options"
                    onClick={() => {
                      handleAddNewStore(store.unitNum, store.name);
                    }}
                  >
                    #{store.unitNum} - {store.name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <button onClick={reset} className="cancel_btn">
            Cancel
          </button>
        </div>
      )}
      {isNew && showEditDialog && (
        <EditDialog
          isNew={isNew}
          onComplete={resetFromAdd}
          unitNum={unitNum}
        ></EditDialog>
      )}
      
    </div>
  );
};
export default Home;
